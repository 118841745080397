import React, { useEffect, useMemo, useRef, useState } from 'react'
import type { CMSSectionProductCarousel } from '@/types/sanity'
import Section from '../Section'
import ProductCardV2 from '@components/Cards/ProductV2/Base'

import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/scss'
import 'swiper/scss/pagination'
import 'swiper/css/free-mode'
import 'swiper/scss/navigation'
import 'swiper/css/thumbs'

import config from 'tailwind.config'

import styles from './SectionProductCarouselV2.module.scss'
import type { Bottomline } from '@/types/yotpo'
import useMediaQuery from '@/hooks/useMediaQuery'

type SectionProductCarouselProps = {
  content: CMSSectionProductCarousel
}

const SectionProductCarouselV2: React.FC<SectionProductCarouselProps> = ({
  content,
}) => {
  const swiperRef = useRef<any>(null)
  const [productBottomlinesMap, setProductBottomlinesMap] = useState<
    Record<string, Bottomline>
  >({})

  const { _id, heading, subtitle, cta, cardBackgroundColor, products } = content

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext()
    }
  }

  useEffect(() => {
    const fetchProductBottomlines = async () => {
      const response = await fetch(
        `${window.location.origin}/api/yotpo-reviews`,
      )
      const data = await response.json()
      setProductBottomlinesMap(data)
    }

    fetchProductBottomlines()
  }, [])

  const maxSlidesPerView = useMediaQuery(
    `(min-width: ${config.theme.screens.md})`,
  )
    ? 4
    : 1

  const slideCards = useMemo(() => {
    if (products.length > maxSlidesPerView) {
      return products
    }

    // Repeat cards to fill the carousel
    const repeatCount = Math.ceil((maxSlidesPerView + 1) / products.length)
    return Array(repeatCount).fill(products).flat()
  }, [products, maxSlidesPerView])

  return (
    <Section
      id={_id}
      size="sm"
      hasWrapper={true}
      theme={content.theme || 'milk-lighter'}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerText}>
            {subtitle && (
              <p className={`${styles.subtitle} type-eyebrow`}>{subtitle}</p>
            )}
            {heading && <h2 className={`${styles.heading}`}>{heading}</h2>}
          </div>

          <button
            className={`${styles.headerNextButton} button button--transparentArrow`}
            type="button"
            onClick={handleNext}
          ></button>
        </div>
        <Swiper
          ref={swiperRef}
          slidesPerView={1.5}
          spaceBetween={16}
          breakpoints={{
            768: {
              spaceBetween: 32,
              slidesPerView: 4,
            },
          }}
          loop={true}
          touchEventsTarget="container"
        >
          {slideCards.map((product, index) => {
            if (!product.card) return null

            return (
              <SwiperSlide key={index}>
                <ProductCardV2
                  key={index}
                  content={product.card}
                  cta={cta}
                  backgroundColor={cardBackgroundColor || '#7272727'}
                  bottomline={productBottomlinesMap[product.card.id]}
                />
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </Section>
  )
}

export default SectionProductCarouselV2
